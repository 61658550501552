import React from 'react'
import './App.css'

import { ReactComponent as MainLogo } from './images/main_logo.svg'
import MainVisual from './images/main_visual.webp'
import StudioImage from './images/studio_image.webp'
import CategoryImage1 from './images/category_image_1.webp'
import CategoryImage2 from './images/category_image_2.webp'
import CategoryImage3 from './images/category_image_3.webp'
import FeatureImage1 from './images/feature_image_1.webp'
import FeatureImage2 from './images/feature_image_2.webp'
import FeatureImage3 from './images/feature_image_3.webp'
import FeatureImage4 from './images/feature_image_4.webp'
import FeatureImage5 from './images/feature_image_5.webp'
import ExampleCompany1 from './images/example_company_1.webp'
import ExampleCompany2 from './images/example_company_2.webp'
import ExampleCompany3 from './images/example_company_3.webp'
import FlowVector from './images/flow_vector.png'
import Hearing from './images/hearing.webp'
import Suggestion from './images/suggestion.webp'
import Production from './images/production.webp'
import Distribution from './images/distribution.webp'
import Feedback from './images/feedback.webp'
import Cross from './images/cross.png'

import { Link } from 'react-scroll'
import { useMediaQuery } from 'react-responsive'
import { push as Menu } from 'react-burger-menu'

function App() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1152px)' })
  const isSmartPhone = useMediaQuery({ query: '(max-width: 1152px)' })

  const [overlay, openOverlay] = React.useState(false)
  const overlayHandler = () => openOverlay(!overlay)

  const menuHandler = () => openOverlay(!overlay)

  return (
    <>
      <header
        style={{
          backgroundColor: '#EEF5F5',
          height: '80px',
        }}
      >
        {isDesktopOrLaptop && (
          <MainLogo
            height={25}
            width={210}
            style={{
              top: '33px',
              left: '29px',
              position: 'absolute',
            }}
          />
        )}
        {isSmartPhone && (
          <MainLogo
            height={25}
            width={210}
            style={{
              top: '27px',
              left: '16px',
              position: 'absolute',
            }}
          />
        )}
        {isSmartPhone && (
          <Menu
            right
            disableAutoFocus
            isOpen={overlay}
            onOpen={() => overlayHandler()}
            onClose={() => overlayHandler()}
            width={'100%'}
            customBurgerIcon={
              <div>
                <div
                  style={{
                    border: '1px solid #FFFFFF',
                    width: '16px',
                    position: 'absolute',
                    top: '21px',
                    left: '19px',
                    borderRadius: '10px',
                  }}
                />
                <div
                  style={{
                    border: '1px solid #FFFFFF',
                    width: '16px',
                    position: 'absolute',
                    top: '27px',
                    left: '19px',
                    borderRadius: '10px',
                  }}
                />
                <div
                  style={{
                    border: '1px solid #FFFFFF',
                    width: '16px',
                    position: 'absolute',
                    top: '33px',
                    left: '19px',
                    borderRadius: '10px',
                  }}
                />
              </div>
            }
            customCrossIcon={<img src={Cross} />}
          >
            <div className="menu-item">
              <Link
                style={{
                  margin: '0px',
                  cursor: 'pointer',
                }}
                to="feature"
                smooth={true}
                onClick={() => menuHandler()}
              >
                音声の特徴
              </Link>
            </div>
            <div className="menu-item">
              <Link
                style={{
                  margin: '0px',
                  cursor: 'pointer',
                }}
                to="example"
                smooth={true}
                onClick={() => menuHandler()}
              >
                活用事例
              </Link>
            </div>
            <div className="menu-item">
              <Link
                style={{
                  margin: '0px',
                  cursor: 'pointer',
                }}
                to="flow"
                smooth={true}
                onClick={() => menuHandler()}
              >
                制作フロー
              </Link>
            </div>
            <div className="menu-item">
              <a
                style={{
                  margin: '0px',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#333333',
                }}
                href="https://ear-s.jp/"
                target="_blank"
                rel="noopener"
              >
                会社情報
              </a>
            </div>
            <div className="menu-item">
              <a
                style={{
                  margin: '0px',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#333333',
                }}
                href="https://docs.google.com/forms/d/e/1FAIpQLSdG4fsja-7mbeu-GLdumlbag34lOrhcWMMWJvyQKg-lufWA3Q/viewform"
                target="_blank"
                rel="noopener"
              >
                お問い合わせ
              </a>
            </div>
          </Menu>
        )}
        {isDesktopOrLaptop && (
          <div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdG4fsja-7mbeu-GLdumlbag34lOrhcWMMWJvyQKg-lufWA3Q/viewform"
              target="_blank"
            >
              <div
                style={{
                  width: '208px',
                  height: '80px',
                  backgroundColor: '#098E93',
                  textAlign: 'center',
                  float: 'right',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    color: '#FFFFFF',
                    paddingTop: '19px',
                    fontSize: '14px',
                  }}
                >
                  <p style={{ margin: '0px' }}>資料請求</p>
                  <p style={{ margin: '0px' }}>お問い合わせはこちら</p>
                </div>
              </div>
            </a>
            <div
              style={{
                width: '500px',
                float: 'right',
                paddingTop: '33px',
                textAlign: 'right',
                fontSize: '14px',
                color: '#333333',
              }}
            >
              <Link
                style={{
                  margin: '0px',
                  marginRight: '48px',
                  cursor: 'pointer',
                }}
                to="feature"
                smooth={true}
              >
                音声の特徴
              </Link>
              <Link
                style={{
                  margin: '0px',
                  marginRight: '48px',
                  cursor: 'pointer',
                }}
                to="example"
                smooth={true}
              >
                活用事例
              </Link>
              <Link
                style={{
                  margin: '0px',
                  marginRight: '48px',
                  cursor: 'pointer',
                }}
                to="flow"
                smooth={true}
              >
                制作フロー
              </Link>
              <a
                style={{
                  margin: '0px',
                  marginRight: '48px',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#333333',
                }}
                href="https://ear-s.jp/"
                target="_blank"
                rel="noopener"
              >
                会社情報
              </a>
            </div>
          </div>
        )}
      </header>
      <div
        style={{
          backgroundColor: '#EEF5F5',
          height: '665px',
          position: 'relative',
        }}
      >
        {isSmartPhone ? (
          <>
            <div
              style={{
                position: 'absolute',
                top: '36px',
                left: '20px',
                color: '#098E93',
              }}
            >
              <h1
                style={{
                  width: '100%',
                  margin: '0px',
                  letterSpacing: '0.08em',
                  fontSize: '32px',
                }}
              >
                Podcast /
                <br />
                ポッドキャスト番組・
                <br />
                音声メディア
                <br />
                立ち上げ支援
              </h1>
              <div
                style={{
                  width: '240px',
                  marginTop: '20px',
                }}
              >
                <MainLogo
                  width={185}
                  height={22}
                  style={{
                    marginTop: '12px',
                  }}
                />
              </div>
            </div>
            <img
              src={MainVisual}
              width={375}
              height={381}
              style={{
                marginTop: '216px',
                float: 'right',
              }}
              alt="main_visual"
            />
          </>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '1152px',
                position: 'relative',
              }}
            >
              <div
                style={{
                  color: '#098E93',
                  padding: '105px 0px 0px 53px',
                }}
              >
                <h1
                  style={{
                    width: '800px',
                    margin: '0px',
                    letterSpacing: '0.08em',
                    fontSize: '48px',
                  }}
                >
                  Podcast / ポッドキャスト番組・音声メディア立ち上げ支援
                </h1>
                <div
                  style={{
                    width: '240px',
                    marginTop: '48px',
                  }}
                >
                  <MainLogo
                    style={{
                      marginTop: '12px',
                    }}
                  />
                </div>
              </div>
              <img
                src={MainVisual}
                width={334}
                style={{
                  marginTop: '245px',
                  position: 'absolute',
                  left: '100%',
                  transform: 'translate(-100%, -100%)',
                }}
                alt="main_visual"
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: isSmartPhone ? '40px' : '120px',
        }}
      >
        <div
          style={{
            width: isSmartPhone ? '100%' : '892px',
            height: isSmartPhone ? '330px' : '501px',
            margin: '0 auto',
          }}
        >
          {isSmartPhone ? null : (
            <img
              src={StudioImage}
              width={313}
              height={501}
              style={{
                display: 'block',
                float: 'left',
              }}
              alt="studio_image"
            />
          )}
          <div
            style={{
              marginLeft: isSmartPhone ? '0px' : '63px',
              float: isSmartPhone ? 'none' : 'right',
              fontSize: isSmartPhone ? '20px' : '28px',
              fontWeight: 'bold',
              lineHeight: isSmartPhone ? '32px' : '50px',
              letterSpacing: '1px',
              color: '#333333',
              width: isSmartPhone ? '345px' : '516px',
              margin: isSmartPhone ? '0 auto' : '0',
            }}
          >
            {isSmartPhone ? (
              <>
                <div
                  style={{
                    height: '100px',
                    marginLeft: '10.5px',
                    marginRight: '10.5px',
                  }}
                >
                  <img
                    src={CategoryImage1}
                    height={100}
                    width={100}
                    style={{
                      marginRight: '12px',
                    }}
                    alt="category_image_1"
                  />
                  <img
                    src={CategoryImage2}
                    height={100}
                    width={100}
                    style={{
                      marginRight: '12px',
                    }}
                    alt="category_image_2"
                  />
                  <img
                    src={CategoryImage3}
                    height={100}
                    width={100}
                    alt="category_image_3"
                  />
                </div>
                <div
                  style={{
                    marginTop: '24px',
                    marginBottom: '42px',
                    marginLeft: '10.5px',
                    marginRight: '10.5px',
                    height: '100px',
                  }}
                >
                  <img
                    src={FeatureImage1}
                    height={100}
                    width={100}
                    style={{
                      marginRight: '12px',
                    }}
                    alt="feature_image_1"
                  />
                  <img
                    src={FeatureImage2}
                    height={100}
                    width={100}
                    style={{
                      marginRight: '12px',
                    }}
                    alt="feature_image_2"
                  />
                  <img
                    src={FeatureImage3}
                    height={100}
                    width={100}
                    alt="feature_image_3"
                  />
                </div>
              </>
            ) : (
              <div
                style={{
                  margin: '0 auto',
                  width: isSmartPhone ? '345px' : '462px',
                  textAlign: 'center',
                }}
              >
                <p style={{ margin: '0px' }}>ear.style studioは音声によって</p>
                <p style={{ margin: '0px' }}>
                  様々な企業課題を解決いたします。
                </p>
              </div>
            )}
            {isSmartPhone ? (
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: '0px' }}>ear.style studioは音声によって</p>
                <p style={{ margin: '0px' }}>
                  様々な企業課題を解決いたします。
                </p>
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginTop: '56px',
                    height: '156px',
                  }}
                >
                  <img
                    src={CategoryImage1}
                    height={156}
                    width={156}
                    style={{
                      marginRight: '24px',
                    }}
                    alt="category_image_1"
                  />
                  <img
                    src={CategoryImage2}
                    height={156}
                    width={156}
                    style={{
                      marginRight: '24px',
                    }}
                    alt="category_image_2"
                  />
                  <img
                    src={CategoryImage3}
                    height={156}
                    width={156}
                    alt="category_image_3"
                  />
                </div>
                <div
                  style={{
                    marginTop: '33px',
                    height: '156px',
                  }}
                >
                  <img
                    src={FeatureImage1}
                    height={156}
                    width={156}
                    style={{
                      marginRight: '24px',
                    }}
                    alt="feature_image_1"
                  />
                  <img
                    src={FeatureImage2}
                    height={156}
                    width={156}
                    style={{
                      marginRight: '24px',
                    }}
                    alt="feature_image_2"
                  />
                  <img
                    src={FeatureImage3}
                    height={156}
                    width={156}
                    alt="feature_image_3"
                  />
                </div>
              </>
            )}
          </div>
          <div style={{ clear: 'both' }} />
        </div>
      </div>
      <div
        style={{
          marginTop: isSmartPhone ? '100px' : '161px',
        }}
      >
        <div
          style={{
            width: isSmartPhone ? '324px' : '793px',
            height: isSmartPhone ? '1331px' : '955px',
            margin: '0 auto',
            color: '#333333',
          }}
        >
          <div
            id="feature"
            style={{
              textAlign: 'center',
              fontSize: '24px',
              lineHeight: '35px',
              letterSpacing: '1px',
              fontWeight: 'bold',
            }}
          >
            <p style={{ margin: '0px' }}>音声の特徴</p>
          </div>
          <div
            style={{
              marginTop: isSmartPhone ? '24px' : '60px',
              height: isSmartPhone ? '562px' : '340px',
            }}
          >
            {isSmartPhone ? (
              <>
                <img
                  src={FeatureImage4}
                  width={240}
                  height={240}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  alt="feature_image_4"
                />
                <div
                  style={{
                    width: '324px',
                    height: '216px',
                    marginTop: '24px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: '22px',
                      lineHeight: '32px',
                      margin: '0px',
                      textAlign: 'center',
                    }}
                  >
                    新たな顧客チャネルの獲得
                  </p>
                  <p
                    style={{
                      fontSize: '14px',
                      lineHeight: '32px',
                      margin: '16px 0px 0px 0px',
                    }}
                  >
                    ワイヤレスイヤホンやスマートスピーカーの普及による音声を聴く機会の増加、それに伴うPodcastコンテンツの急増から、音声コンテンツの聴取ユーザーが急速に拡大しています。特にPodcastユーザーは20~30代の情報感度の高い若者層が多く、非常に盛り上がっています。新たに生まれたこの大きなチャンスを活かし、顧客獲得の新たなチャネルとして音声メディアへの参入をご支援いたします。
                  </p>
                </div>
              </>
            ) : (
              <>
                <img
                  src={FeatureImage4}
                  width={340}
                  height={340}
                  style={{
                    display: 'block',
                    float: 'left',
                  }}
                  alt="feature_image_4"
                />
                <div
                  style={{
                    width: '429px',
                    height: '216px',
                    float: 'left',
                    marginTop: '62px',
                    marginLeft: '24px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: '22px',
                      lineHeight: '32px',
                      margin: '0px',
                    }}
                  >
                    新たな顧客チャネルの獲得
                  </p>
                  <p
                    style={{
                      fontSize: '14px',
                      lineHeight: '32px',
                      marginTop: '24px',
                    }}
                  >
                    ワイヤレスイヤホンやスマートスピーカーの普及による音声を聴く機会の増加、それに伴うPodcastコンテンツの急増から、音声コンテンツの聴取ユーザーが急速に拡大しています。特にPodcastユーザーは20~30代の情報感度の高い若者層が多く、非常に盛り上がっています。新たに生まれたこの大きなチャンスを活かし、顧客獲得の新たなチャネルとして音声メディアへの参入をご支援いたします。
                  </p>
                </div>
                <div style={{ clear: 'both' }} />
              </>
            )}
          </div>
          <div
            style={{
              marginTop: '40px',
              height: isSmartPhone ? '530px' : '340px',
            }}
          >
            {isSmartPhone ? (
              <>
                <img
                  src={FeatureImage5}
                  width={240}
                  height={240}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  alt="feature_image_5"
                />
                <div
                  style={{
                    width: '324px',
                    height: '216px',
                    marginTop: '24px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: '22px',
                      lineHeight: '32px',
                      margin: '0px',
                      textAlign: 'center',
                    }}
                  >
                    顧客ロイヤリティの向上
                  </p>
                  <p
                    style={{
                      fontSize: '14px',
                      lineHeight: '32px',
                      margin: '16px 0px 0px 0px',
                    }}
                  >
                    音声メディアはテキストメディアより、特に顧客ロイヤリティの向上が得意です。文字だけでは伝えきれないことも「声」で直接伝えることで、情報量だけでなくパーソナリティに対しても親近感が湧きやすく、テキストより踏み込んだコンテンツを発信することができます。ear.style
                    studioでは番組の企画や構成からご提案いたします。
                  </p>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: '429px',
                    height: '216px',
                    float: 'left',
                    marginTop: '62px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: '22px',
                      lineHeight: '32px',
                      margin: '0px',
                    }}
                  >
                    顧客ロイヤリティの向上
                  </p>
                  <p
                    style={{
                      fontSize: '14px',
                      lineHeight: '32px',
                      marginTop: '24px',
                    }}
                  >
                    音声メディアはテキストメディアより、特に顧客ロイヤリティの向上が得意です。文字だけでは伝えきれないことも「声」で直接伝えることで、情報量だけでなくパーソナリティに対しても親近感が湧きやすく、テキストより踏み込んだコンテンツを発信することができます。ear.style
                    studioでは番組の企画や構成からご提案いたします。
                  </p>
                </div>
                <img
                  src={FeatureImage5}
                  width={340}
                  height={340}
                  style={{
                    display: 'block',
                    float: 'left',
                    marginLeft: '24px',
                  }}
                  alt="feature_image_5"
                />
                <div style={{ clear: 'both' }} />
              </>
            )}
          </div>
          <div
            style={{
              height: '60px',
              paddingTop: '80px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a
              style={{
                border: 'none',
                backgroundColor: '#098E93',
                color: '#FFFFFF',
                width: '244px',
                height: '60px',
                fontSize: '18px',
                lineHeight: '27px',
                letterSpacing: '1px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSdG4fsja-7mbeu-GLdumlbag34lOrhcWMMWJvyQKg-lufWA3Q/viewform"
              target="_blank"
            >
              お問い合わせする
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: isSmartPhone ? '100px' : '160px',
        }}
      >
        <div
          id="example"
          style={{
            textAlign: 'center',
            fontSize: '24px',
            lineHeight: '36px',
            letterSpacing: '1px',
            fontWeight: 'bold',
            color: '#333333',
          }}
        >
          <p style={{ margin: '0px' }}>活用事例</p>
        </div>
        <div
          style={{
            marginTop: isSmartPhone ? '24px' : '40px',
            backgroundColor: '#F9F9F9',
            height: isSmartPhone ? '1475px' : '531px',
          }}
        >
          {isSmartPhone ? (
            <div
              style={{
                width: '324px',
                margin: '0 auto',
                paddingTop: '24px',
              }}
            >
              <div
                style={{
                  width: '324px',
                  height: '470px',
                }}
              >
                <img
                  src={ExampleCompany1}
                  height={170}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  alt="example_company_1"
                />
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333333',
                    marginTop: '24px',
                  }}
                >
                  <p
                    style={{
                      margin: '0px',
                    }}
                  >
                    音声チャンネルのご提供
                  </p>
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '28px',
                    color: '#333333',
                  }}
                >
                  <p
                    style={{
                      margin: '16px 0px 0px 0px',
                    }}
                  >
                    動画配信サービスHuluにて、既存ユーザー様への満足度向上施策として、新たに音声コンテンツの配信を開始されるにあたり、弊社からボイスドラマ作品を提供しております。Hulu限定コンテンツとして出演声優陣によるオーディオコメンタリーも制作・提供しています。
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '324px',
                  height: '470px',
                  marginTop: '8px',
                }}
              >
                <img
                  src={ExampleCompany2}
                  height={170}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  alt="example_company_2"
                />
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333333',
                    marginTop: '24px',
                  }}
                >
                  <p
                    style={{
                      margin: '0px',
                    }}
                  >
                    音声作品の提供本数60本以上
                  </p>
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '28px',
                    color: '#333333',
                  }}
                >
                  <p
                    style={{
                      margin: '16px 0px 0px 0px',
                    }}
                  >
                    博報堂DYメディアパートナーズ様が運営するアプリ「ラジオクラウド」にボイスドラマ作品を提供し、ユーザーの滞在時間の向上に繋げています。
                    脚本執筆、声優のキャスティング、収録、演出、音声編集まで全てear.style
                    studioが行っています。
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '324px',
                  height: '470px',
                  marginTop: '8px',
                }}
              >
                <img
                  src={ExampleCompany3}
                  height={170}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  alt="example_company_3"
                />
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333333',
                    marginTop: '24px',
                  }}
                >
                  <p
                    style={{
                      margin: '0px',
                    }}
                  >
                    2020年Podcastアワード推薦作品
                  </p>
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '28px',
                    color: '#333333',
                  }}
                >
                  <p
                    style={{
                      margin: '16px 0px 0px 0px',
                    }}
                  >
                    LIGHT UP COFFEE代表の川野様のPodcastを制作しています。
                    ear.style
                    studioではトークテーマの企画から収録、編集、配信まで支援を行っています。
                    川野様はすでにYouTube、Instagram、Twitterでの発信は着手されていた中で、さらなる顧客のファン化のために音声配信にも力を入れられており、その結果、2020年Podcastアワード推薦作品に選出されました。
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '914px',
                margin: '0 auto',
              }}
            >
              <div
                style={{
                  width: '268px',
                  height: '441px',
                  float: 'left',
                  marginTop: '40px',
                  marginRight: '55px',
                }}
              >
                <img
                  src={ExampleCompany1}
                  height={141}
                  alt="example_company_1"
                />
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333333',
                    marginTop: '32px',
                  }}
                >
                  <p
                    style={{
                      margin: '0px',
                    }}
                  >
                    音声チャンネルのご提供
                  </p>
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '24px',
                    color: '#333333',
                  }}
                >
                  <p
                    style={{
                      margin: '12px 0px 0px 0px',
                    }}
                  >
                    動画配信サービスHuluにて、既存ユーザー様への満足度向上施策として、新たに音声コンテンツの配信を開始されるにあたり、弊社からボイスドラマ作品を提供しております。Hulu限定コンテンツとして出演声優陣によるオーディオコメンタリーも制作・提供しています。
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '268px',
                  height: '441px',
                  float: 'left',
                  marginTop: '40px',
                  marginRight: '55px',
                }}
              >
                <img
                  src={ExampleCompany2}
                  height={141}
                  alt="example_company_2"
                />
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333333',
                    marginTop: '32px',
                  }}
                >
                  <p
                    style={{
                      margin: '0px',
                    }}
                  >
                    音声作品の提供本数60本以上
                  </p>
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '24px',
                    color: '#333333',
                  }}
                >
                  <p
                    style={{
                      margin: '12px 0px 0px 0px',
                    }}
                  >
                    博報堂DYメディアパートナーズ様が運営するアプリ「ラジオクラウド」にボイスドラマ作品を提供し、ユーザーの滞在時間の向上に繋げています。
                    脚本執筆、声優のキャスティング、収録、演出、音声編集まで全てear.style
                    studioが行っています。
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '268px',
                  height: '441px',
                  float: 'left',
                  marginTop: '40px',
                }}
              >
                <img
                  src={ExampleCompany3}
                  height={141}
                  alt="example_company_3"
                />
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333333',
                    marginTop: '32px',
                  }}
                >
                  <p
                    style={{
                      margin: '0px',
                    }}
                  >
                    2020年Podcastアワード推薦作品
                  </p>
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '24px',
                    color: '#333333',
                  }}
                >
                  <p
                    style={{
                      margin: '12px 0px 0px 0px',
                    }}
                  >
                    LIGHT UP COFFEE代表の川野様のPodcastを制作しています。
                    ear.style
                    studioではトークテーマの企画から収録、編集、配信まで支援を行っています。
                    川野様はすでにYouTube、Instagram、Twitterでの発信は着手されていた中で、さらなる顧客のファン化のために音声配信にも力を入れられており、その結果、2020年Podcastアワード推薦作品に選出されました。
                  </p>
                </div>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          marginTop: isSmartPhone ? '100px' : '160px',
        }}
      >
        <div
          style={{
            width: isSmartPhone ? '324px' : '698px',
            margin: '0 auto',
          }}
        >
          <div
            id="flow"
            style={{
              textAlign: 'center',
              fontSize: '24px',
              lineHeight: '35px',
              letterSpacing: '1px',
              fontWeight: 'bold',
              color: '#333333',
            }}
          >
            <p style={{ margin: '0px' }}>制作フロー</p>
          </div>
          <div
            style={{
              marginTop: '60px',
              height: isSmartPhone ? '2292px' : '1120px',
            }}
          >
            {isSmartPhone ? null : (
              <img
                src={FlowVector}
                width={40}
                style={{
                  marginTop: '60px',
                  display: 'block',
                  float: 'left',
                }}
                alt="flow_vector"
              />
            )}
            <div
              style={{
                width: isSmartPhone ? '262px' : '604px',
                height: isSmartPhone ? '368px' : '160px',
                float: isSmartPhone ? 'none' : 'left',
                marginLeft: isSmartPhone ? '31px' : '54px',
                marginRight: isSmartPhone ? '31px' : '0px',
              }}
            >
              <img
                src={Hearing}
                width={isSmartPhone ? 262 : 160}
                height={isSmartPhone ? 262 : 160}
                style={{
                  display: 'block',
                  float: isSmartPhone ? 'none' : 'left',
                }}
                alt="hearing"
              />
              <div
                style={{
                  width: isSmartPhone ? '262px' : '404px',
                  height: isSmartPhone ? '90px' : '160px',
                  float: isSmartPhone ? 'none' : 'left',
                  marginLeft: isSmartPhone ? '0px' : '40px',
                  marginTop: isSmartPhone ? '16px' : '0px',
                }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    letterSpacing: '1px',
                    fontWeight: 'bold',
                    margin: '0px',
                    textAlign: isSmartPhone ? 'center' : 'left',
                  }}
                >
                  ヒアリング
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '32px',
                    margin: '8px 0px 0px 0px',
                  }}
                >
                  ご要望や課題について、弊社スタッフがお客様に直接ヒアリングを行います。
                </p>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <div
              style={{
                width: isSmartPhone ? '262px' : '604px',
                height: isSmartPhone ? '398px' : '160px',
                float: isSmartPhone ? 'none' : 'left',
                marginTop: isSmartPhone ? '36px' : '80px',
                marginLeft: isSmartPhone ? '31px' : '54px',
                marginRight: isSmartPhone ? '31px' : '0px',
              }}
            >
              <img
                src={Suggestion}
                width={isSmartPhone ? 262 : 160}
                height={isSmartPhone ? 262 : 160}
                style={{
                  display: 'block',
                  float: isSmartPhone ? 'none' : 'left',
                }}
                alt="suggestion"
              />
              <div
                style={{
                  width: isSmartPhone ? '262px' : '404px',
                  height: isSmartPhone ? '90px' : '160px',
                  float: isSmartPhone ? 'none' : 'left',
                  marginLeft: isSmartPhone ? '0px' : '40px',
                  marginTop: isSmartPhone ? '16px' : '0px',
                }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    letterSpacing: '1px',
                    fontWeight: 'bold',
                    margin: '0px',
                    textAlign: isSmartPhone ? 'center' : 'left',
                  }}
                >
                  企画提案
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '32px',
                    margin: '8px 0px 0px 0px',
                  }}
                >
                  お客様にとって最適な形になるよう、音声によるソリューションをご提案いたします。
                </p>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <div
              style={{
                width: isSmartPhone ? '262px' : '604px',
                height: isSmartPhone ? '452px' : '160px',
                float: isSmartPhone ? 'none' : 'left',
                marginTop: isSmartPhone ? '36px' : '80px',
                marginLeft: isSmartPhone ? '31px' : '54px',
                marginRight: isSmartPhone ? '31px' : '0px',
              }}
            >
              <img
                src={Production}
                width={isSmartPhone ? 262 : 160}
                height={isSmartPhone ? 262 : 160}
                style={{
                  display: 'block',
                  float: isSmartPhone ? 'none' : 'left',
                }}
                alt="production"
              />
              <div
                style={{
                  width: isSmartPhone ? '262px' : '404px',
                  height: isSmartPhone ? '90px' : '160px',
                  float: isSmartPhone ? 'none' : 'left',
                  marginLeft: isSmartPhone ? '0px' : '40px',
                  marginTop: isSmartPhone ? '16px' : '0px',
                }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    letterSpacing: '1px',
                    fontWeight: 'bold',
                    margin: '0px',
                    textAlign: isSmartPhone ? 'center' : 'left',
                  }}
                >
                  制作
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '32px',
                    margin: '8px 0px 0px 0px',
                  }}
                >
                  収録方法はスタジオ、リモートのどちらでもご対応可能です。収録した音源については、ear.style
                  studio側でノイズを除去し、雰囲気に合うBGMやSEによる演出まで行います。
                </p>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <div
              style={{
                width: isSmartPhone ? '262px' : '604px',
                height: isSmartPhone ? '508px' : '160px',
                float: isSmartPhone ? 'none' : 'left',
                marginTop: isSmartPhone ? '36px' : '80px',
                marginLeft: isSmartPhone ? '31px' : '54px',
                marginRight: isSmartPhone ? '31px' : '0px',
              }}
            >
              <img
                src={Distribution}
                width={isSmartPhone ? 262 : 160}
                height={isSmartPhone ? 262 : 160}
                style={{
                  display: 'block',
                  float: isSmartPhone ? 'none' : 'left',
                }}
                alt="distribution"
              />
              <div
                style={{
                  width: isSmartPhone ? '262px' : '404px',
                  height: isSmartPhone ? '90px' : '160px',
                  float: isSmartPhone ? 'none' : 'left',
                  marginLeft: isSmartPhone ? '0px' : '40px',
                  marginTop: isSmartPhone ? '16px' : '0px',
                }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    letterSpacing: '1px',
                    fontWeight: 'bold',
                    margin: '0px',
                    textAlign: isSmartPhone ? 'center' : 'left',
                  }}
                >
                  配信
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '32px',
                    margin: '8px 0px 0px 0px',
                  }}
                >
                  Podcastとして配信する場合は、Apple Podcast、Spotify、Google
                  Podcast、Amazon
                  Musicといった複数のプラットフォームに配信いたします。また、制作した音声コンテンツをお客様のオウンドメディアやSNS上で配信することも可能です。
                </p>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <div
              style={{
                width: isSmartPhone ? '262px' : '604px',
                height: isSmartPhone ? '424px' : '160px',
                float: isSmartPhone ? 'none' : 'left',
                marginTop: isSmartPhone ? '36px' : '80px',
                marginLeft: isSmartPhone ? '31px' : '54px',
                marginRight: isSmartPhone ? '31px' : '0px',
              }}
            >
              <img
                src={Feedback}
                width={isSmartPhone ? 262 : 160}
                height={isSmartPhone ? 262 : 160}
                style={{
                  display: 'block',
                  float: isSmartPhone ? 'none' : 'left',
                }}
                alt="feedback"
              />
              <div
                style={{
                  width: isSmartPhone ? '262px' : '404px',
                  height: isSmartPhone ? '90px' : '160px',
                  float: isSmartPhone ? 'none' : 'left',
                  marginLeft: isSmartPhone ? '0px' : '40px',
                  marginTop: isSmartPhone ? '16px' : '0px',
                }}
              >
                <p
                  style={{
                    fontSize: '18px',
                    lineHeight: '26px',
                    letterSpacing: '1px',
                    fontWeight: 'bold',
                    margin: '0px',
                    textAlign: isSmartPhone ? 'center' : 'left',
                  }}
                >
                  フィードバック
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: isSmartPhone ? '28px' : '32px',
                    margin: '8px 0px 0px 0px',
                  }}
                >
                  再生回数や登録者数などのアナリティクスレポートの提供を行い、その結果から今後の改善策も具体的に提案いたします。
                </p>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: isSmartPhone ? '100px' : '120px',
        }}
      >
        <div
          style={{
            height: '232px',
            backgroundColor: '#EEF5F5',
            textAlign: 'center',
            fontSize: '24px',
            lineHeight: '36px',
            letterSpacing: '1px',
            fontWeight: 'bold',
            color: '#098E93',
            paddingTop: '60px',
          }}
        >
          <p style={{ margin: '0px' }}>資料請求</p>
          <p style={{ margin: '0px' }}>お問い合わせはこちらから</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a
              style={{
                border: 'none',
                backgroundColor: '#098E93',
                color: '#FFFFFF',
                width: '244px',
                height: '60px',
                fontSize: '18px',
                lineHeight: '27px',
                letterSpacing: '1px',
                cursor: 'pointer',
                fontWeight: 'normal',
                marginTop: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSdG4fsja-7mbeu-GLdumlbag34lOrhcWMMWJvyQKg-lufWA3Q/viewform"
              target="_blank"
            >
              お問い合わせする
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          height: isSmartPhone ? '193px' : '88px',
          backgroundColor: '#098E93',
          paddingTop: '40px',
        }}
      >
        <div
          style={{
            width: isSmartPhone ? '155px' : '480px',
            color: '#FFFFFF',
            margin: '0 auto',
            fontSize: '14px',
            lineHeight: '20px',
          }}
        >
          <a
            style={{
              marginRight: isSmartPhone ? '0px' : '48px',
              textDecoration: 'none',
              color: '#FFFFFF',
              display: isSmartPhone ? 'block' : 'inline',
              textAlign: 'center',
            }}
            href="https://www.evernote.com/shard/s456/client/snv?noteGuid=30db4c7f-c138-4ab0-b0d5-584a13e7c8e1&noteKey=5d47ce0706093ba0fff014213a530bdc&sn=https%3A%2F%2Fwww.evernote.com%2Fshard%2Fs456%2Fsh%2F30db4c7f-c138-4ab0-b0d5-584a13e7c8e1%2F5d47ce0706093ba0fff014213a530bdc&title=Privacy%2BPolicy"
          >
            プライバシーポリシー
          </a>
          <a
            style={{
              marginTop: isSmartPhone ? '16px' : '0px',
              marginRight: isSmartPhone ? '0px' : '48px',
              textDecoration: 'none',
              color: '#FFFFFF',
              display: isSmartPhone ? 'block' : 'inline',
              textAlign: 'center',
            }}
            href="https://www.evernote.com/shard/s456/client/snv?noteGuid=5e89608a-e896-4582-9fd2-1b3839a2cc33&noteKey=c7e9801d290b7d6d000c566922f73493&sn=https%3A%2F%2Fwww.evernote.com%2Fshard%2Fs456%2Fsh%2F5e89608a-e896-4582-9fd2-1b3839a2cc33%2Fc7e9801d290b7d6d000c566922f73493&title=%25E5%2588%25A9%25E7%2594%25A8%25E8%25A6%258F%25E7%25B4%2584"
          >
            利用規約
          </a>
          <a
            style={{
              marginTop: isSmartPhone ? '16px' : '0px',
              marginRight: isSmartPhone ? '0px' : '48px',
              textDecoration: 'none',
              color: '#FFFFFF',
              display: isSmartPhone ? 'block' : 'inline',
              textAlign: 'center',
            }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSdG4fsja-7mbeu-GLdumlbag34lOrhcWMMWJvyQKg-lufWA3Q/viewform"
          >
            お問い合わせ
          </a>
          <a
            style={{
              marginTop: isSmartPhone ? '16px' : '0px',
              textDecoration: 'none',
              color: '#FFFFFF',
              display: isSmartPhone ? 'block' : 'inline',
              textAlign: 'center',
            }}
            href="https://ear-s.jp/"
            target="_blank"
            rel="noopener"
          >
            会社情報
          </a>
        </div>
        <div
          style={{
            width: isSmartPhone ? '100%' : '480px',
            fontSize: '12px',
            lineHeight: '16px',
            color: 'rgba(249,249,249,0.7)',
            textAlign: 'center',
            margin: '0 auto',
            paddingTop: isSmartPhone ? '12px' : '0px',
          }}
        >
          <p>©︎2021-2024 any style, Inc.</p>
        </div>
      </div>
    </>
  )
}

export default App
